<template>
  <div id="Store" class="store">
    <Side type="darken" />
    <div class="store-container">
      <i>City Store</i>
      <h1>线下新零售店</h1>
      <div class="city">
        <img src="../../assets/img/icon_city.svg">
        <span>云南</span>
        <span>Yunnan</span>
      </div>
      <div class="store-list">
        <div class="item">
          <div class="cover"><img src="../../assets/img/store_case.jpg"></div>
          <div class="sub">
            <img src="../../assets/img/icon_store_add.svg">
            <span>穿金路煤机厂721号</span>
          </div>
          <div class="sub">
            <img src="../../assets/img/icon_store_time.svg">
            <span>09:00-22:00</span>
          </div>
          <div class="intro">占地面积1280㎡，室外采用唐式山水造景，开阔露天庭院2间，新中式风雅包房6间，大堂可同时接纳至少20位以上客人。</div>
          <div class="area">盘龙区</div>
        </div>
        <div class="item">
          <div class="cover"><img src="../../assets/img/store_case.jpg"></div>
          <div class="sub">
            <img src="../../assets/img/icon_store_add.svg">
            <span>穿金路煤机厂721号</span>
          </div>
          <div class="sub">
            <img src="../../assets/img/icon_store_time.svg">
            <span>09:00-22:00</span>
          </div>
          <div class="intro">占地面积1280㎡，室外采用唐式山水造景，开阔露天庭院2间，新中式风雅包房6间，大堂可同时接纳至少20位以上客人。</div>
          <div class="area">盘龙区</div>
        </div>
        <div class="item">
          <div class="cover"><img src="../../assets/img/store_case.jpg"></div>
          <div class="sub">
            <img src="../../assets/img/icon_store_add.svg">
            <span>穿金路煤机厂721号</span>
          </div>
          <div class="sub">
            <img src="../../assets/img/icon_store_time.svg">
            <span>09:00-22:00</span>
          </div>
          <div class="intro">占地面积1280㎡，室外采用唐式山水造景，开阔露天庭院2间，新中式风雅包房6间，大堂可同时接纳至少20位以上客人。</div>
          <div class="area">盘龙区</div>
        </div>
        <div class="item">
          <div class="cover"><img src="../../assets/img/store_case.jpg"></div>
          <div class="sub">
            <img src="../../assets/img/icon_store_add.svg">
            <span>穿金路煤机厂721号</span>
          </div>
          <div class="sub">
            <img src="../../assets/img/icon_store_time.svg">
            <span>09:00-22:00</span>
          </div>
          <div class="intro">占地面积1280㎡，室外采用唐式山水造景，开阔露天庭院2间，新中式风雅包房6间，大堂可同时接纳至少20位以上客人。</div>
          <div class="area">盘龙区</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Side from '@/components/Side'
export default {
  components: {
    Side
  }
}
</script>

<style lang="scss">
@import './store_mobile.scss';
@import './store.scss';
</style>
